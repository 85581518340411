// Imports
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../node_modules/css-loader/dist/runtime/getUrl.js";
import ___CSS_LOADER_URL_IMPORT_0___ from "./assets/logo-black.png";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "a {\n  text-decoration: none;\n  color: white;\n}\n\n@keyframes fadeInAnimation {\n  0% {\n    opacity: 0;\n  }\n  100% {\n    opacity: 1;\n  }\n}\nbody {\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n  background-attachment: fixed;\n  background-repeat: no-repeat;\n  background-color: black;\n  background-position: center;\n  background-size: 135%;\n  color: white;\n  animation: fadeInAnimation ease 3s;\n  animation-iteration-count: 1;\n  animation-fill-mode: forwards;\n}", "",{"version":3,"sources":["webpack://src/App.scss"],"names":[],"mappings":"AAAA;EACI,qBAAA;EACA,YAAA;AACJ;;AAEA;EACI;IACI,UAAA;EACN;EACE;IACI,UAAA;EACN;AACF;AAEA;EACI,yDAAA;EACA,4BAAA;EACA,4BAAA;EACA,uBAAA;EACA,2BAAA;EACA,qBAAA;EACA,YAAA;EAEA,kCAAA;EACA,4BAAA;EACA,6BAAA;AADJ","sourcesContent":["a {\n    text-decoration: none;\n    color:white;\n}\n\n@keyframes fadeInAnimation {\n    0% {\n        opacity: 0;\n    }\n    100% {\n        opacity: 1;\n     }\n}\n\nbody {\n    background-image: url('./assets/logo-black.png');\n    background-attachment: fixed;\n    background-repeat: no-repeat;\n    background-color: black;\n    background-position: center;\n    background-size: 135%;\n    color:white;\n\n    animation: fadeInAnimation ease 3s;\n    animation-iteration-count: 1;\n    animation-fill-mode: forwards;\n} "],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
