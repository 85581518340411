import React from "react";
//import {Link} from 'react-router-dom';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
// import Button from '@material-ui/core/Button';
// import {ReactComponent as Logo} from '../../assets/logo.svg';
import {ReactComponent as Insta} from '../../assets/instagram.svg';
import {ReactComponent as AppleMusicLogoBlack} from '../../assets/apple-music-black.svg';
import {ReactComponent as SpotifyMusicLogo} from '../../assets/spotify.svg';
import {ReactComponent as BeatportMusicLogo} from '../../assets/beatport.svg';

import './header.styles.scss'

const Header = () => {
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };
    return (
        <div className='header'>
            <div className='logo-container'>
            </div>
            <div className='options'>
                {/*<Link className='option' to='/music'>
                    MUSIC
                </Link>*/}
                <Button className='option' style={{color: 'white', cursor:'pointer'}} aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}>
                    Listen
                </Button>
                <Menu
                    id="simple-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                >
                    <MenuItem onClick={handleClose}>
                        <a className='option' target='_blank' rel="noopener noreferrer" href='https://music.apple.com/us/artist/lechuga-caliente/1576738537'>
                            <AppleMusicLogoBlack style={{width:95,height:50}} fill="white" stroke="white" />
                        </a>
                    </MenuItem>
                    <MenuItem onClick={handleClose}>
                        <a className='option' target='_blank' rel="noopener noreferrer" href='https://open.spotify.com/artist/1fIVPZcct81Y84VEzXtOJt'>
                            <SpotifyMusicLogo style={{width:95,height:50}} fill="black" stroke="black" />
                        </a>
                    </MenuItem>
                    <MenuItem onClick={handleClose}>
                        <a className='option' target='_blank' rel="noopener noreferrer" href='https://www.beatport.com/artist/lechuga-caliente/999711'>
                            <BeatportMusicLogo style={{width:95,height:50}} fill="black" stroke="black" />
                        </a>
                    </MenuItem>
                </Menu>
                <a className='option' target='_blank' rel="noopener noreferrer" href='https://www.instagram.com/lechuga.caliente'>
                    <Insta fill="white" stroke="black" />
                </a>
            </div>
        </div>
    )
}

export default Header