import * as React from 'react';
import IconButton from '@mui/material/IconButton';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import {ReactComponent as AppleMusicLogoBlack} from '../../assets/apple-music-black.svg';
import {ReactComponent as SpotifyMusicLogo} from '../../assets/spotify.svg';
import {ReactComponent as BeatportMusicLogo} from '../../assets/beatport.svg';

const albums = [{
    id:1,
    title:"I've Waited A Long Time For This",
    coverUrl:'../../assets/uploads/mountain-black.png',
    label:"View Askew",
    releaseDate:'08-20-2021',
    releaseLinks:[
        {
            source:'Apple',
            url:'https://music.apple.com/us/album/ive-waited-a-long-time-for-this/1576738536',
            icon: <AppleMusicLogoBlack style={{width:190,height:100}} fill="black" stroke="black" />
        },
        {
            source:'Spotify',
            url:'https://open.spotify.com/album/1bHbAgKv1DncC4fCA2QGSy',
            icon: <SpotifyMusicLogo style={{width:190,height:100}} fill="black" stroke="black" />

        },
        {
            source:'Beatport',
            url:'https://www.beatport.com/release/ive-waited-a-long-time-for-this/3452729',
            icon: <BeatportMusicLogo style={{width:190,height:100}} fill="black" stroke="black" />

        },
    ]
}]

const theme = createTheme();

export default function MusicPage() {
  return (
    <ThemeProvider theme={theme}>
      <main>
        {/* Hero unit */}
        <Container sx={{ py: 8 }} maxWidth="lg">
          {/* End hero unit */}
          <Grid container spacing={1}>
            {
                albums.map(({id, title, coverUrl, releaseDate, releaseLinks}) => {
                    return (
                        <Grid item key={id} xs={12}>
                        {albums.map(({id, title, coverUrl, label, releaseDate, releaseLinks}) => {
                            return (
                                <Card key={id} sx={{ display: 'flex', border: '1px solid white' }}>
                                    <CardMedia
                                        component="img"
                                        sx={{ width: 500 }}
                                        image={coverUrl}
                                        alt={title}
                                    />
                                    <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                                        <CardContent sx={{ flex: '1 0 auto' }}>
                                            <Typography component="div" variant="h5">
                                            {title}
                                            </Typography>
                                            <Typography variant="subtitle1" color="text.secondary" component="div">
                                                Release Date: {releaseDate}
                                            </Typography>
                                        </CardContent>
                                        <Box sx={{ display: 'flex', flexDirection:'column', justifyContent:'space-evenly', alignItems: 'center'}}>
                                            {releaseLinks.map(({source, url, icon}) => {
                                                return(
                                                    <IconButton icon={icon} key={source} sx={{width:190,height:100}} onClick={() => window.open(url)} aria-label={source} component="div">
                                                        {icon}
                                                    </IconButton>
                                                );
                                            })}
                                            
                                        </Box>
                                    </Box>
                                </Card>
                            )
                        })}
                        </Grid>
                    );
                })
            }
          </Grid>
          
        </Container>
      </main>
    </ThemeProvider>
  );
}
