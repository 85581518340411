import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';

export const Copyright = () => {
	return (
	  <Typography>
		{'Copyright © '}
		<Link color="inherit" href="https://www.lechugacaliente.com/">
		  Lechuga Caliente
		</Link>{' '}
		{new Date().getFullYear()}
		{'.'}
	  </Typography>
	);
}
